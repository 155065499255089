import { useCallback, useEffect, useState } from "react";
import { TableChart } from "@meteor/frontend-core";
import { Flex, Radio, RadioChangeEvent, Switch } from "antd";
import { chain, eq, get, isEmpty, isNil, isNumber, maxBy, minBy, set } from "lodash";
import { useTranslation } from 'react-i18next';

type ChartProps = {
  data?: any[];
};

const typeMapping = {
  productivity: {
    label: 'Productivity',
    keys: {
      percent: {
        line: 'upperLimit', symbol: '+',
        min: -100, max: 100,
        x: 'productivityDeltaRatio', y: 'deliveryDelta',
        name: {
          x: 'Productivity\n改善量[%]', y: '納期改善量 [Day]',
        }
      },
      absolute: {
        line: 'upperLimitProductivity', symbol: '',
        x: 'productivity', y: 'deliveryDelta',
        name: {
          x: 'Productivity\n改善量', y: '納期改善量 [Day]',
        }
      }
    }
  },
  mh: {
    label: 'MH',
    keys: {
      percent: {
        line: 'upperLimit', symbol: '+',
        min: -100, max: 100,
        x: 'mhDeltaRatio', y: 'deliveryDelta',
        name: { x: 'MH\n改善量[%]', y: '納期改善量 [Day]' }
      },
      absolute: {
        line: 'upperLimitMh', symbol: '',
        x: 'mh', y: 'deliveryDelta',
        name: { x: 'MH\n改善量', y: '納期改善量 [Day]' }
      },
    }
  }
};

// handle for radio button
const handleRadioOptions = () =>
  chain(typeMapping)
    .map((value, key) => ({ label: value.label, value: key }))
    .value();

// handle data for xAxis options type
const handleDataForXAxis = (target: any[], configure: any): object => {
  if (isEmpty(target)) return [];
  return {
    name: get(configure, ['name', 'x']),
    min: get(configure, 'min'), max: get(configure, 'max'),
    axisLabel: {
      formatter: function(value: number) {
        if (isNumber(value) && value > 0) {
          return `${get(configure, 'symbol', '')}${value}`;
        }
        return value;
      }
    },
    minorTick: {
      show: true
    },
    minorSplitLine: {
      show: true
    }
  };
}

// handle data for yAxis options type
const handleDataForYAxis = (target: any[], configure: any): object => {
  if (isEmpty(target)) return [];
  const key = get(configure, 'y');
  const min = get(minBy(target, key), key);
  const max = get(maxBy(target, key), key);
  return  {
    name: get(configure, ['name', 'y']),
    min, max,
    minorTick: {
      show: true
    },
    minorSplitLine: {
      show: true
    }
  };
}

// handle data for series
const handleDataForSeries = (target: any[], configure: any): object => {
  if (isEmpty(target)) return [];
  const x = get(configure, 'x');
  const y = get(configure, 'y');
  const data = chain(target).orderBy([x, 'asc']).map((i) => ([ i[x], i[y] ])).value();
  const result = { type: 'line', showSymbol: false, clip: true, data };
  const markLineX = get(target, [0, get(configure, 'line')]);
  if (!isNil(markLineX)) {
    set(result, 'markLine', {
      symbol: ['none', 'none'],
      lineStyle: {
        color: 'red',
        width: 2,
      },
      data: [
        { name: 'start', xAxis: markLineX, label: { formatter: '' } },
        [
          { name: 'start: start'},
          { name: 'end: start' },
        ]
      ]
    });
  }
  return [ result ];
}

// handle data for tooltip
const handleDataForTooltip = (type: 'percent' | 'absolute'): object => {
  return {
    trigger: 'axis',
    axisPointer: { type: 'cross' },
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    formatter: function (param) {
      param = param[0];
      return [
        // 'Date: ' + param.name + '<hr size=1 style="margin: 3px 0">',
        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#1A4F99;"></span>',
        '改善量: ' + param.data[0] + `${ eq(type, 'percent') ? '%' : '' }<br/>`,
        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:green;"></span>',
        '納期改善量: ' + param.data[1] + ' days<br/>',
      ].join('');
    }
  }
}

const CriticalChainChart: React.FC<ChartProps> = (props) => {
  const { t } = useTranslation();

  const { data } = props;
  const title = {
    value: '',
    styles: {
      height: '0px'
    },
  };
  const [radioValue, setRadioValue] = useState<string>(
    chain(typeMapping).keys().get(0).value()
  );
  const [options, setOptions] = useState<any>({ series: [] });
  // true: percent | false: absolute
  const [switchValue, setSwitchValue] = useState(true);
  const [loading, setLoading] = useState(true);

  const switchChange = useCallback((checked: boolean) => {
    setLoading(true);
    setSwitchValue(checked);
  }, []);
  const radioChange = useCallback(({ target: { value } }: RadioChangeEvent) => {
    setLoading(true);
    setRadioValue(value);
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      const value = chain(typeMapping).keys().get(0).value();
      setLoading(true);
      setRadioValue(value);
    }
  }, [data]);

  useEffect(() => {
    if (radioValue && !isEmpty(data)) {
      const type = switchValue ? 'percent' : 'absolute';
      const configure = get(typeMapping, [radioValue, 'keys', type]);
      if (isEmpty(configure)) return;
      setOptions({
        grid: { bottom: 20 },
        tooltip: handleDataForTooltip(type),
        xAxis: handleDataForXAxis(data, configure),
        legend: { type: 'scroll' },
        yAxis: handleDataForYAxis(data, configure),
        series: handleDataForSeries(data, configure)
      });
    }
    setLoading(false)
  }, [radioValue, switchValue]);
  return (
    <>
      <TableChart
        select={{
          type: 'switches',
          node: <>
            <Flex align={'center'} justify={'space-between'}>
              <Radio.Group
                options={handleRadioOptions()}
                value={radioValue}
                onChange={radioChange}
                optionType="button"
                buttonStyle="solid"
                size="small"
              />
              <Switch
                value={switchValue}
                checkedChildren= {t('aipskd.optionDetail.ratio')} unCheckedChildren={t('aipskd.optionDetail.actualValue')}
                onChange={switchChange} />
            </Flex>
          </>
        }}
        tooltips={[]}
        title={title}
        chartOptions={options}
        height={560}
        loading={loading}
        isBank={isEmpty(data)}
      />
    </>
  );
}

export default CriticalChainChart;