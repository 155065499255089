import React, { useEffect, useRef, useState } from 'react';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react';
import { DateHelper, Gantt, PresetManager, ProjectModel } from '@bryntum/gantt';
import { chain, filter, includes, isNil, map, maxBy, pick, round, set, toNumber } from 'lodash';
import { getRandomString } from '../utils/commonUtil';
import '@bryntum/gantt/gantt.stockholm.css';
import '../styles/common/critical-chain.css';
import { Modal } from 'antd';
import CriticalChainChart from './kpi-chart/critical-chain-chart';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

PresetManager.registerPreset('monthAndYear', {
  base: 'monthAndYear',
  tickWidth: 70, // 设置宽度
  headers: [
    {
      unit: 'YEAR',
      align: 'center',
      dateFormat: 'Y',
    },
    {
      unit: 'MONTH',
      align: 'center',
      dateFormat: 'MMM',
    },
  ],
});

const ganttConfig: BryntumGanttProps = {
  selectionMode: {
    multiSelect: false,
    dragSelect: false,
    cell: false,
    column: false,
    rowNumber: false,
  },
  scrollTaskIntoViewOnCellClick: true,
  subGridConfigs: {
    fixed: {
      // No resizing of the fixed column region
      resizable: false,
      // Set a lower weight than the built-in "locked" section to have it appear to the left
      weight: 1,
    },
    locked: {
      // A scrollable section with the main columns
      width: 340,
      weight: 2,
    },
  },
  columns: [
    {
      text: 'Activity Name',
      field: 'name',
      width: 220,
      renderer: (task) => `${task.record.name || '-'}`,
      editor: false,
    },
    {
      text: 'Activity ID',
      field: 'taskId',
      width: 160,
      renderer: (task) => `${task.record.taskId || '-'}`,
      editor: false,
    },
    {
      type: 'percent',
      text: 'Sensitivity Index',
      field: 'bottleneckIndex',
      editor: false,
      readOnly: true,
      showValue: true,
      width: 160,
    },
    {
      text: '-',
      field: 'isOptimizationTarget',
      editor: false,
      // showValue: true,
      width: 120,
      renderer: (task) => {
        if (task.record.isOptimizationTarget) {
          return '最適化対象';
        }
        return '最適化対象外';
      },
    },
    { type: 'name', field: 'name', width: 320, editor: false, hidden: true },
  ],
  barMargin: 5,
  rowHeight: 20,
  project: {
    taskStore: {
      fields: ['taskId', 'bottleneckIndex', 'isOptimizationTarget', 'name'],
    },
    stm: {
      autoRecord: true,
    },
    resetUndoRedoQueuesAfterLoad: true,
    calendarsData: [
      {
        id: 'general',
        name: 'General',
        intervals: [
          {
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false,
          },
        ],
      },
    ],
  },
  viewPreset: 'monthAndYear',
  dependenciesFeature: {
    allowCreate: false,
    drawOnScroll: false,
  },
  percentBarFeature: {
    allowResize: false,
  },
  taskEditFeature: false,
  taskMenuFeature: false,
  taskDragFeature: false,
  taskResizeFeature: false,
  taskDragCreateFeature: false,
  criticalPathsFeature: {
    disabled: true,
  },
  filterFeature: true,
  progressLineFeature: {
    disabled: true,
  },
  taskNonWorkingTimeFeature: true,
  timeRangesFeature: true,
  taskTooltipFeature: {
    template: (data) => {
      const {
        startDate,
        endDate,
        taskRecord: { taskId, duration, bottleneckIndex },
      } = data;
      return `<div>
        <div style="font-weight: bolder">${taskId}</div>
        <div style="display: flex; justify-content: space-between;">
          <div>
            ${!isNil(startDate) ? `<div>Start: </div>` : ''} 
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>End: </div>` : ''}
            ${!isNil(duration) ? `<div>Duration: </div>` : ''}
            ${!isNil(bottleneckIndex) ? `<div>Sensitivity Index: </div>` : ''}
          </div>
          <div style="margin-left: 5px; text-align: right">
            ${!isNil(startDate) ? `<div>${DateHelper.format(startDate, 'MMM DD, YYYY')}</div>` : ''} 
            ${
              !isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${DateHelper.format(endDate, 'MMM DD, YYYY')}</div>` : ''
            } 
            ${!isNil(duration) ? `<div>${round(duration, 1)} days</div>` : ''}
            ${!isNil(bottleneckIndex) ? `<div>${bottleneckIndex}%</div>` : ''}
          </div>
        </div>
      </div>`;
    },
  },
  taskRenderer: ({ taskRecord, renderData }) => {
    if (taskRecord.isRed) {
      renderData.style = `background-color: red`;
      return;
    }
    // 根据任务的某个属性设置颜色
    if (taskRecord.isMinimumDuration) {
      renderData.style = `background-color: #808080`;
    } else {
      renderData.style = `background-color: #9fd9f6`;
    }
    return '';
  },
  onRenderRow: ({ record, row }) => {
    const element: HTMLElement = row._elementsArray?.[0];
    if (!element) return;
    if (record?.isOptimizationTarget) {
      element.style.cursor = 'pointer';
      element.style.backgroundColor = '#ffffff';
    } else {
      element.style.cursor = 'default';
      element.style.backgroundColor = '#e3e4e5';
    }
  },
};

const handleTasksData = (data: any[]) => {
  const source = chain(data)
    .groupBy('task.taskId')
    .map((array) => {
      const max = maxBy(array, 'bottleneckIndex');
      const isOptimizationTarget = filter(array, (i) => i.isOptimizationTarget);
      const isMinimumDuration = filter(array, (i) => i.isMinimumDuration);
      const result = {
        // ...max,
        id: max.taskId,
        taskId: max.taskId,
        name: max['task.taskName'],
        bottleneckIndex: toNumber(max.bottleneckIndex?.toFixed(1)),
        oldBottleneckIndex: max.bottleneckIndex,
        startDate: max.startDate,
        endDate: dayjs(max.startDate).isAfter(max.endDate) ? max.startDate: max.endDate,
        echartData: array,
        isRed: false,
        isOptimizationTarget: isOptimizationTarget.length == array.length,
        isMinimumDuration: isMinimumDuration.length == array.length,
      };
      return result;
    })
    .keyBy('taskId')
    // .sortBy('startDate', 'asc')
    .value();
  // 为了防止Max有相同的情况，将数组值以`oldBottleneckIndex`为维度分组
  const maxOfSource = chain(source).values().groupBy('oldBottleneckIndex').value();
  // 判断分组后的key（oldBottleneckIndex）哪个最大
  const maxOfSourceKey = chain(maxOfSource)
    .keys()
    .map((i) => toNumber(i))
    .max()
    .value();
  // 按照最大的oldBottleneckIndex的值将对应的数据进行修正
  chain(maxOfSource)
    .get(maxOfSourceKey)
    .map('taskId')
    .forEach((i) => set(source, [i, 'isRed'], true))
    .value();
  return chain(source).values().sortBy('startDate', 'asc').value();
};

const handleDependenciesData = (data: any[], taskIds: string[]) => {
  return chain(data)
    .filter((item) => includes(taskIds, item.fromTask) && includes(taskIds, item.toTask))
    .map((i) => pick(i, ['fromTask', 'toTask', 'lag']))
    .value();
};

const CriticalChain: React.FC<any> = (props) => {
  const { t } = useTranslation();

  const { height, data, optimizationId } = props;

  const gantt = useRef<any>(null);

  const [ganttKey, setGanttKey] = useState<string>();

  const ganttInstance = (): Gantt => gantt.current?.instance;

  const ganttProject = (): ProjectModel => ganttInstance().project;

  const [selected, setSelected] = useState<any>();

  const projectReady = useRef(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [ganttCardConfig, setGanttCardConfig] = useState(ganttConfig);

  useEffect(() => {
    if (!data) {
      return;
    }
    setGanttKey(getRandomString());
  }, [data]);

  useEffect(() => {
    setGanttCardConfig({
      ...ganttConfig,
    });
  }, [optimizationId]);

  useEffect(() => {
    if (!gantt.current || !ganttKey) {
      return;
    }

    ganttInstance().on('cellClick', (item) => {
      // if (item.column.field === 'taskId' && item.record.isOptimizationTarget) {
      if (item.record.isOptimizationTarget) {
        setSelected(item.record);
        setModalOpen(true);
      }
    });

    projectReady.current = false;

    ganttProject().on({
      dataReady() {
        projectReady.current = true;
      },
    });
    const tasksData = handleTasksData(data.tasksData);
    const dependenciesData = handleDependenciesData(data.dependenciesData, map(tasksData, 'id'));
    ganttProject()
      .loadInlineData(
        { tasksData, dependenciesData }
        // { tasksData: tasksData.filter((i) => dayjs(i.startDate).isBefore(i.endDate)), dependenciesData }
        // {
        // tasksData,
        // dependenciesData: [
        //   // { fromTask: 'CWP-5C172-D_01', toTask: 'CWP-5C171-D_01', lag: 0 },
        //   // { fromTask: 'CWP-5C152-D_01', toTask: 'CWP-5C151-D_01', lag: 0 },
        //   // { fromTask: 'CWP-5C172-F_01', toTask: 'CWP-5C171-F_01', lag: 0 },
        //   // { fromTask: 'CWP-59134-F_01', toTask: 'CWP-5913B-ALL_01', lag: 10 },
        //   // { fromTask: 'CWP-5913B-ALL_01', toTask: 'CWP-5913J-ALL_01', lag: 10 },
        //   // { fromTask: 'CWP-59134-F_01', toTask: 'CWP-5913J-ALL_01', lag: 10 },
        //   // { fromTask: 'CWP-5C151-D_01', toTask: 'CWP-5C171-D_01', lag: 20 },
        //   // { fromTask: 'CWP-5C152-D_01', toTask: 'CWP-5C172-D_01', lag: 0 },
        //   // { fromTask: 'CWP-54131-D_01', toTask: 'CWP-5C152-D_01', lag: 40 },
        //   // { fromTask: 'CWP-54131-D_01', toTask: 'CWP-5C151-D_01', lag: 40 },
        //   // { fromTask: 'CWP-5C171-D_01', toTask: 'CWP-5C171-F_01', lag: 40 },
        //   // 数据库中字段 type 导致图标绘制出错
        // ]
        // }
      )
      .then(() => {})
      .catch(() => {});
  }, [ganttKey]);

  return (
    <>
      <div className="critiacl-chain-container">
        <BryntumGantt key={ganttKey} ref={gantt} height={height} {...ganttCardConfig} />

        <Modal
          destroyOnClose
          title={
            <span>
              {selected?.taskId} {t('aipskd.optionDetail.sensitivity')}
            </span>
          }
          width={document.body.clientWidth * 0.5}
          keyboard={false}
          centered
          styles={{
            body: {
              height: document.body.clientHeight * 0.5,
            },
          }}
          open={modalOpen}
          onCancel={() => {
            setModalOpen(false);
          }}
          footer={null}
          getContainer={() => document.querySelector('.aipskd')}
        >
          <CriticalChainChart data={selected?.echartData} />
        </Modal>
      </div>
    </>
  );
};
export default CriticalChain;
