/* eslint-disable max-len */
import { Button, Card, Checkbox, Form, Input, Modal, Select, Space, DatePicker, Table, InputRef, Empty, App, Radio, Flex, Tag } from 'antd';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { chain, eq, find, get, orderBy, remove } from 'lodash';
import dayjs from 'dayjs';
import useStorage from '../hooks/useStorage';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import TableCard from '../components/table-card';
import {
  WORK_ACTIVITY_TABLE_COLUMNS,
  WORK_PACKAGE_RELATION_TABLE_COLUMNS,
  CALENDAR_TABLE_COLUMNS,
  WORK_PACKAGE_BOQ_TABLE_COLUMNS,
  WORK_ACTIVITY_RESOURCE_TYPE_USAGE_TABLE_COLUMNS,
  RESOURCE_LOADING_CONSTRAINT_TABLE_COLUMNS,
  AREA_TABLE_COLUMNS,
  MINIMUM_DURATION_TABLE_COLUMNS,
  FWBS_RESOURCE_LOADING_TABLE_COLUMNS,
  RESOURCE_DAILY_LOADING_TABLE_COLUMNS,
  RESOURCE_TYPE_TABLE_COLUMNS,
  MANHOUR_UNIT_COST_TABLE_COLUMNS,
  CONSTRUCTION_UNIT_COST_TABLE_COLUMNS,
  STRATEGY_MATRIX_TABLE_COLUMNS,
  FHD_BUFFER_RATE_SET_TABLE_COLUMNS,
  WORK_PACKAGE_KPI_TABLE_COLUMNS,
  WORK_PACKAGE_RISK_LEVEL_TABLE_COLUMNS,
  WORKFRONT_MANAGMENT_TABLE_COLUMNS,
} from '../components/input-table';
import { PROJECT_PLAN_TABLE } from '../components/project-plan-table';
import { ACTION_EVENT } from '../consts/action-event';
import useInterval from '../hooks/useInterval';
import { useTable } from '../hooks/useTable';
import APIList from '../http/ApiList';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export const OPERATION = {
  ADD: 'add',
  MODIFY: 'modify',
};

export const INPUT_TABLE = {
  SCHEDULE_NETWORK: 'network',
  STRATEGY_MATRIX: 'matrix',
  RESOURCE: 'resource',
};

const ProjectPlan: React.FC = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('projectId');
  const { setItem, getItem } = useStorage();
  const { message } = App.useApp();
  // context
  const { globalEvent, projectEditorAuth, setSelectOptions, authentication } = useContext(AppContext);

  // Project
  const [project, setProject] = useState<any>(null);
  const [projectList, setProjectList] = useState([]);

  // modal
  const [operation, setOperation] = useState<string>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataSnapshots, setDataSnapshots] = useState([]);
  const [planInfo, setPlanInfo] = useState<any>(null);

  // delete modal
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [namePlaceholder, setNamePlaceholder] = useState('');
  const [deleteItem, setDeleteItem] = useState<any>();
  const [inputName, setInputName] = useState('');
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);

  // show input table modal
  const [isInputTableModalOpen, setInputTableModalOpen] = useState(false);

  // form
  const [form] = Form.useForm();
  const [time, setTime] = useState(null);
  const [optimizeDateValid, setOptimizeDateValid] = useState(true);

  const selectedOptDate = useRef<any>({});

  // ジョブランニングしたら、計画は繰り返しリロード必要ない
  const [jobApiRuning, setJobApiRuning] = useState(false);

  // 工事データ最適化案名 Options
  const [referenceOptions, setReferenceOptions] = useState([]);

  // interval
  useInterval(() => {
    getProjectPlans(false);
    setSelectOptions(null);
  }, time);

  const dropdown = (
    <Space wrap>
      <span style={{ fontWeight: 800 }}>{`${t('aipskd.project.project')}: `}</span>
      <Select
        showSearch
        // allowClear
        style={{ width: '220px' }}
        value={project}
        onChange={(value, option) => {
          resetProjectStorage(value);
          setProject(value);
        }}
        placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.project.project'))}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        options={projectList}
        optionRender={(option) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1 }} className="ellipsis">
              {option.data.label}
            </div>
            <Tag style={{ fontSize: 10 }}>{option.data.projectRole}</Tag>
          </div>
        )}
        labelRender={(props: any) => {
          const { systemRole, appGroups } = authentication;
          const { label, value } = props;
          if (label) {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} title={label}>
                <div style={{ flex: 1 }} className="ellipsis">
                  {label}
                </div>
                <Tag style={{ height: 20, marginInlineEnd: 0 }}>
                  {systemRole === 'ADMIN' ? 'ADMIN' : appGroups.find((role) => role.projectId === value)?.projectRole?.toLocaleUpperCase()}
                </Tag>
              </div>
            );
          }
          return null;
        }}
      />
      {projectEditorAuth(project) ? (
        <>
          <Button
            style={{ marginLeft: '20px' }}
            type="primary"
            disabled={!project}
            onClick={() => {
              showModal(OPERATION.ADD);
            }}
          >
            {t('aipskd.plan.create')}
          </Button>
        </>
      ) : (
        <></>
      )}
    </Space>
  );

  const [stageOptions, setStageOptions] = useState([]);

  useEffect(() => {
    APIList.getStages()
      .get()
      .then((res: any[]) => {
        setStageOptions(
          res.map(({ stageId, stageName }) => ({
            label: stageName,
            value: stageName,
            key: stageId,
          }))
        );
      });
  }, []);

  useEffect(() => {
    getProjectList();
  }, [authentication]);

  useEffect(() => {
    // 最初化はnull、何も選択しない時はundefined
    if (project !== null) {
      getProjectPlans();
    }
  }, [project]);

  useEffect(() => {
    if (project !== null && globalEvent && globalEvent.eventType === ACTION_EVENT.RELOAD_DATA) {
      getProjectPlans();
    }
  }, [globalEvent]);

  const epcDataSnapshotsMapping = (data = dataSnapshots) => ({
    Engineering: orderBy(
      data
        .filter((item) => item.optimizeName === '設計')
        .map((item) => ({
          ...item,
          value: item.snapshot,
        })),
      'snapshot',
      'desc'
    ),
    Procurement: orderBy(
      data
        .filter((item) => item.optimizeName === '調達')
        .map((item) => ({
          ...item,
          value: item.snapshot,
        })),
      'snapshot',
      'desc'
    ),
    Construction: orderBy(
      data
        .filter((item) => item.optimizeName === '工事')
        .map((item) => ({
          ...item,
          value: item.snapshot,
        })),
      'snapshot',
      'desc'
    ),
  });

  // modal function
  const showModal = (op: string, record?: any) => {
    setOperation(op);
    setReferenceOptions([]);
    setModalOpen(true);
    setOptimizeDateValid(true);
    form.resetFields();
    setPlanInfo(record);
    dispatch({ type: 'minDateInit' });
    selectedOptDate.current = {};
    if (op === OPERATION.MODIFY && record) {
      setTimeout(() => {
        const dataSnapshots = projectList.find((item) => item.projectId === project).dataSnapshots;
        // reference options setting
        const referenceMapping = get(
          chain(record.optimize).find(i => eq('工事', i.optimizeName)).value(),
          'referenceDataList', {}
        );
        const referenceOptsionsValue = chain(referenceMapping).map((v) => ({ label: v.optimizationName, value: v.optimizationId })).value();
        setReferenceOptions(referenceOptsionsValue);
        // const dataSnapshot = dataSnapshots.find((item) => item.snapshot === snapshot);
        const designCrossSection = record.designCrossSection || epcDataSnapshotsMapping(record?.dataSnapshots).Engineering[0]?.snapshot;
        const supplyCrossSection = record.supplyCrossSection || epcDataSnapshotsMapping(record?.dataSnapshots).Procurement[0]?.snapshot;
        const constructionCrossSection =
          record.constructionCrossSection || epcDataSnapshotsMapping(record?.dataSnapshots).Construction[0]?.snapshot;
        form.setFieldsValue({
          ...record,
          // 如果有改动，则显示改动的，否则显示默认的日期
          designOptimizeStartDate: record.designOptimizeDate
            ? dayjs(record.designOptimizeDate[0])
            : dataSnapshots.find((item) => (item.snapshot === designCrossSection && item.optimizeName === '設計'))?.optStartDate
            ? dayjs(dataSnapshots.find((item) => (item.snapshot === designCrossSection && item.optimizeName === '設計'))?.optStartDate)
            : null,
          designOptimizeEndDate: record.designOptimizeDate
            ? dayjs(record.designOptimizeDate[1])
            : dataSnapshots.find((item) => (item.snapshot === designCrossSection && item.optimizeName === '設計'))?.optEndDate
            ? dayjs(dataSnapshots.find((item) => (item.snapshot === designCrossSection && item.optimizeName === '設計'))?.optEndDate)
            : null,
          supplyOptimizeStartDate: record.supplyOptimizeDate
            ? dayjs(record.supplyOptimizeDate[0])
            : dataSnapshots.find((item) => (item.snapshot === supplyCrossSection && item.optimizeName === '調達'))?.optStartDate
            ? dayjs(dataSnapshots.find((item) => (item.snapshot === supplyCrossSection && item.optimizeName === '調達'))?.optStartDate)
            : null,
          supplyOptimizeEndDate: record.supplyOptimizeDate
            ? dayjs(record.supplyOptimizeDate[1])
            : dataSnapshots.find((item) => (item.snapshot === supplyCrossSection && item.optimizeName === '調達'))?.optEndDate
            ? dayjs(dataSnapshots.find((item) => (item.snapshot === supplyCrossSection && item.optimizeName === '調達'))?.optEndDate)
            : null,
          constructionOptimizeStartDate: record.constructionOptimizeDate
            ? dayjs(record.constructionOptimizeDate[0])
            : dataSnapshots.find((item) => (item.snapshot === constructionCrossSection && item.optimizeName === '工事'))?.optStartDate
            ? dayjs(dataSnapshots.find((item) => (item.snapshot === constructionCrossSection && item.optimizeName === '工事'))?.optStartDate)
            : null,
          constructionOptimizeEndDate: record.constructionOptimizeDate
            ? dayjs(record.constructionOptimizeDate[1])
            : dataSnapshots.find((item) => (item.snapshot === constructionCrossSection && item.optimizeName === '工事'))?.optEndDate
            ? dayjs(dataSnapshots.find((item) => (item.snapshot === constructionCrossSection && item.optimizeName === '工事'))?.optEndDate)
            : null,
          designCrossSection,
          supplyCrossSection,
          constructionCrossSection,
          designOptimizeLogic: record.designOptimizeLogic
            ? record.designOptimizeLogic
            : designOptimizeDisabled(record?.dataSnapshots)
            ? null
            : 'Forward(Std.)',
          supplyOptimizeLogic: record.supplyOptimizeLogic,
          constructionOptimizeLogic: record.constructionOptimizeLogic
            ? record.constructionOptimizeLogic
            : constructionOptimizeDisabled(record?.dataSnapshots)
            ? null
            : 'Backward(Std.)',
        });
        form.setFieldValue('referenceData', get(find(record.optimize, (i) => i.optimizeName === '設計'), 'referenceData'));
      }, 100);
    } else {
      form.setFieldsValue({
        designCrossSection: epcDataSnapshotsMapping(record?.dataSnapshots).Engineering[0]?.snapshot,
        supplyCrossSection: epcDataSnapshotsMapping(record?.dataSnapshots).Procurement[0]?.snapshot,
        constructionCrossSection: epcDataSnapshotsMapping(record?.dataSnapshots).Construction[0]?.snapshot,
        designOptimizeLogic: designOptimizeDisabled(record?.dataSnapshots) ? null : 'Forward(Std.)',
        constructionOptimizeLogic: constructionOptimizeDisabled(record?.dataSnapshots) ? null : 'Backward(Std.)',
      });
      if (epcDataSnapshotsMapping(record?.dataSnapshots).Engineering[0]?.snapshot) {
        dataSnapshotChangeHandler(epcDataSnapshotsMapping(record?.dataSnapshots).Engineering[0]?.snapshot, '設計');
      }
      if (epcDataSnapshotsMapping(record?.dataSnapshots).Procurement[0]?.snapshot) {
        dataSnapshotChangeHandler(epcDataSnapshotsMapping(record?.dataSnapshots).Procurement[0]?.snapshot, '調達');
      }
      if (epcDataSnapshotsMapping(record?.dataSnapshots).Construction[0]?.snapshot) {
        dataSnapshotChangeHandler(epcDataSnapshotsMapping(record?.dataSnapshots).Construction[0]?.snapshot, '工事');
      }
    }
  };

  const handleCancel = () => {
    setPlanInfo(null);
    setModalOpen(false);
    form.resetFields();
  };

  // delete modal function
  const deleteModalReset = () => {
    setInputName('');
    setDeleteDisabled(true);
    setDeleteModalOpen(false);
  };

  const showDeleteModal = (record?: any) => {
    setNamePlaceholder(record.planName);
    setDeleteItem(record);
    setDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    deleteModalReset();
  };

  const handleInputChange = (event) => {
    setInputName(event.target.value);
    if (event.target.value === deleteItem.planName) {
      setDeleteDisabled(false);
    } else {
      setDeleteDisabled(true);
    }
  };

  const handleDelete = (record?: any) => {
    deleteDataHandler(record);
    deleteModalReset();
  };

  const deleteDataHandler = (record: any) => {
    setTableLoading(true);
    APIList.deleteProjectPlan()
      .del({ id: record.planId })
      .then(() => {
        message.success(t('aipskd.common.deleteMessage').replace('{placeholder}', t('aipskd.plan.plan')));
        getProjectPlans();
        setSelectOptions(null);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // input table display
  const [searchedInfo, setSearchedInfo] = useState([]);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => {
    confirm();
    remove(searchedInfo, (item) => item.column === dataIndex);
    setSearchedInfo([...searchedInfo, { column: dataIndex, searchedText: selectedKeys[0] }]);
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => {
    clearFilters();
    confirm();
    const newSearchedInfo = [...searchedInfo];
    const index = newSearchedInfo.findIndex((item) => item.column === dataIndex);
    if (index !== -1) {
      newSearchedInfo.splice(index, 1);
      setSearchedInfo(newSearchedInfo);
    }
    // console.log(searchedInfo);
  };

  const getColumnSearchProps = (dataIndex: any, placeholder): ColumnType<any> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
            {t('common.reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('common.search')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] !== undefined && record[dataIndex] !== null
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getColumns = (columns) => {
    return columns.map((column) => {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex, column.title),
        render: (text, record) => {
          const content = column.render ? column.render(text, record) : text;
          const dataIndex = column.dataIndex;
          const searchedInfoItem = searchedInfo.find((info) => info.column === dataIndex);
          const highlightText = searchedInfoItem ? searchedInfoItem.searchedText : '';

          const isColumnSearched = searchedInfo.some((info) => info.column === dataIndex);

          return isColumnSearched ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[highlightText]}
              autoEscape
              textToHighlight={content !== null && content !== undefined ? content.toString() : ''}
            />
          ) : (
            <span>{content !== null && content !== undefined ? content : '-'}</span>
          );
        },
      };
    });
  };

  const inputTables = {
    [INPUT_TABLE.SCHEDULE_NETWORK]: {
      tableList: [
        { label: 'Work Activity', value: 'workActivity', pageSize: 3000 },
        {
          label: 'Work Package Relation',
          value: 'workPackageRelation',
          pageSize: 10000,
        },
        { label: 'Calendar', value: 'calendar', pageSize: 10000 },
        { label: 'Work Package Workfront Category Relation', value: 'workfrontManagement', pageSize: 10000 },
      ],
      defaultTable: 'workActivity',
      options: {
        workActivity: getColumns(WORK_ACTIVITY_TABLE_COLUMNS),
        workPackageRelation: getColumns(WORK_PACKAGE_RELATION_TABLE_COLUMNS),
        calendar: getColumns(CALENDAR_TABLE_COLUMNS),
        workfrontManagement: getColumns(WORKFRONT_MANAGMENT_TABLE_COLUMNS),
      },
    },
    [INPUT_TABLE.STRATEGY_MATRIX]: {
      tableList: [
        { label: 'Work Package BoQ', value: 'workPackageBoQ', pageSize: 10000 },
        {
          label: 'Work Activity Resource Type Usage',
          value: 'workActivityResourceTypeUsage',
          pageSize: 10000,
        },
        {
          label: 'Resource Loading Constraint',
          value: 'resourceLoadingConstraint',
          pageSize: 10000,
        },
        { label: 'Area', value: 'area', pageSize: 10000 },
        {
          label: 'Minimum Duration',
          value: 'minimumDuration',
          pageSize: 10000,
        },
        {
          label: 'FWBS Resource Loading',
          value: 'fwbsResourceLoading',
          pageSize: 10000,
        },
        {
          label: 'Resource Daily Loading',
          value: 'resourceDailyLoading',
          pageSize: 10000,
        },
        { label: 'Resource Type', value: 'resourceType', pageSize: 10000 },
        {
          label: 'Manhour Unit Cost',
          value: 'manhourUnitCost',
          pageSize: 10000,
        },
        {
          label: 'Construction Unit Cost',
          value: 'constructionUnitCost',
          pageSize: 10000,
        },
      ],
      defaultTable: 'workPackageBoQ',
      options: {
        workPackageBoQ: getColumns(WORK_PACKAGE_BOQ_TABLE_COLUMNS),
        workActivityResourceTypeUsage: getColumns(WORK_ACTIVITY_RESOURCE_TYPE_USAGE_TABLE_COLUMNS),
        resourceLoadingConstraint: getColumns(RESOURCE_LOADING_CONSTRAINT_TABLE_COLUMNS),
        area: getColumns(AREA_TABLE_COLUMNS),
        minimumDuration: getColumns(MINIMUM_DURATION_TABLE_COLUMNS),
        fwbsResourceLoading: getColumns(FWBS_RESOURCE_LOADING_TABLE_COLUMNS),
        resourceDailyLoading: getColumns(RESOURCE_DAILY_LOADING_TABLE_COLUMNS),
        resourceType: getColumns(RESOURCE_TYPE_TABLE_COLUMNS),
        manhourUnitCost: getColumns(MANHOUR_UNIT_COST_TABLE_COLUMNS),
        constructionUnitCost: getColumns(CONSTRUCTION_UNIT_COST_TABLE_COLUMNS),
      },
    },
    [INPUT_TABLE.RESOURCE]: {
      tableList: [
        { label: 'Strategy Matrix', value: 'strategyMatrix', pageSize: 10000 },
        {
          label: 'FHD Buffer Rate Set',
          value: 'fhdBufferRateSet',
          pageSize: 10000,
        },
        { label: 'Work Package KPI', value: 'workPackageKpi', pageSize: 10000 },
        {
          label: 'Work Package Risk Level',
          value: 'workPackageRiskLevel',
          pageSize: 10000,
        },
      ],
      defaultTable: 'strategyMatrix',
      options: {
        strategyMatrix: getColumns(STRATEGY_MATRIX_TABLE_COLUMNS),
        fhdBufferRateSet: getColumns(FHD_BUFFER_RATE_SET_TABLE_COLUMNS),
        workPackageKpi: getColumns(WORK_PACKAGE_KPI_TABLE_COLUMNS),
        workPackageRiskLevel: getColumns(WORK_PACKAGE_RISK_LEVEL_TABLE_COLUMNS),
      },
    },
  };

  const [selectedOptimize, setSelectedOptimize] = useState('');
  const [tableList, setTableList] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedTable, setSelectedTable] = useState('');
  const [selectedPageSize, setSelectedPageSize] = useState();
  const [inputTableCol, setInputTableCol] = useState([]);
  const [inputTableData, setInputTableData] = useState([]);
  const [inputTableTotal, setInputTableTotal] = useState(0);
  const [inputTableLoading, setInputTableLoading] = useState(false);

  const showInputTable = (op: string, record?: any) => {
    setSelectedOptimize(record.optimizeId);
    const inputTable = inputTables[op];
    setSelectedType(op);
    setTableList(inputTable.tableList);
    setSelectedTable(inputTable.defaultTable);
    setSelectedPageSize((inputTable.tableList.find((item) => item.value === inputTable.defaultTable) as any)?.pageSize);
    setInputTableModalOpen(true);
  };

  const handleTableChange = (value: string, record: any) => {
    setSelectedTable(value);
    setSelectedPageSize(record.pageSize);
    setInputTableCol(inputTables[selectedType].options[value]);
  };

  useEffect(() => {
    if (inputTables[selectedType]) {
      setInputTableCol(inputTables[selectedType].options[selectedTable]);
    }
  }, [searchedInfo]);

  const handleInputTableClose = () => {
    setInputTableModalOpen(false);
  };

  useEffect(() => {
    setInputTableData([]);
    setInputTableLoading(true);
    setInputTableTotal(0);
    if (selectedTable) {
      setInputTableCol(inputTables[selectedType].options[selectedTable]);
      if (selectedPageSize) {
        APIList.getInput()
          .post({
            tableName: selectedTable,
            optimizeId: selectedOptimize,
            pageNum: 1,
            pageSize: selectedPageSize,
          })
          .then((res: any) => {
            const { list, total } = res;
            setInputTableTotal(total);
            if (list) {
              if (total <= selectedPageSize) {
                setInputTableData(list);
                setInputTableLoading(false);
              } else {
                const count = (total - selectedPageSize) / selectedPageSize;
                let dataList = [];
                const apis = [];
                for (let index = 0; index < count; index++) {
                  apis.push(
                    APIList.getInput().post({
                      tableName: selectedTable,
                      optimizeId: selectedOptimize,
                      pageNum: index + 2,
                      pageSize: selectedPageSize,
                    })
                  );
                }

                Promise.all(apis).then((respList) => {
                  dataList = list.concat(...respList.map((item) => item.list));
                  setInputTableData(dataList);
                  setInputTableLoading(false);
                });
              }
            }
          })
          .catch((err) => {
            console.error(err);
            setInputTableLoading(false);
          });
      } else {
        APIList.getInput()
          .post({
            tableName: selectedTable,
            optimizeId: selectedOptimize,
          })
          .then((res: any) => {
            const { list } = res;
            if (list) {
              setInputTableData(list);
              setInputTableTotal(list.length);
              setInputTableLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setInputTableLoading(false);
          });
      }
    }
  }, [selectedTable, selectedOptimize]);

  // table function
  const tableTop = 206;
  const [property, setTableData, setSelectedRowKeys, setTableLoading] = useTable({
    options: PROJECT_PLAN_TABLE,
    tableTop,
    showModal,
    showDeleteModal,
    showInputTable,
    deleteDataHandler,
    customProperty: { projectEditorAuth, setTime, setJobApiRuning },
  });

  // button loading
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  useEffect(() => {
    if (projectList && projectList.length > 0) {
      let projectSelected;
      let cachedProject;
      const dataCrossSection = getItem('dataCrossSection', 'local');
      if (dataCrossSection) {
        cachedProject = JSON.parse(dataCrossSection).project;
      }
      // router first
      if (projectId) {
        projectSelected = projectId;
      } else if (cachedProject) {
        projectSelected = cachedProject;
      } else {
        projectSelected = projectList[0].projectId;
      }
      // set to default when cached (or clicked) project is removed.
      if (projectList.findIndex((item) => item.value === projectSelected) < 0) {
        projectSelected = projectList[0].projectId;
      }
      // reset Storage when project is changed.
      if (cachedProject !== projectSelected) {
        resetProjectStorage(projectSelected);
      }
      setProject(projectSelected);
    }
  }, [projectList]);

  const getProjectList = () => {
    if (!authentication) {
      return;
    }
    setTableLoading(true);
    const { systemRole, appGroups } = authentication;
    APIList.getProjects()
      .get({})
      .then((res: any) => {
        if (res) {
          const option = res.map((item) => ({
            ...item,
            value: item.projectId,
            label: item.projectId,
            projectRole:
              systemRole === 'ADMIN'
                ? 'ADMIN'
                : appGroups.find((role) => role.projectId === item.projectId)?.projectRole?.toLocaleUpperCase(),
          }));
          setProjectList(option);
          if (option.length === 0) {
            setTableLoading(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getProjectPlans = (useLoading = true) => {
    if (useLoading) {
      setTableLoading(true);
    }
    setDataSnapshots(projectList.find((item) => item.projectId === project)?.dataSnapshots || []);
    APIList.getProjectPlans()
      .get({ projectId: project })
      .then((data: any) => {
        if (jobApiRuning) {
          return;
        }
        if (data) {
          let needInterval = false;
          const tableData = data.map((item) => ({
            key: item.planId + item.optimizeId,
            ...item,
            optimizeNames: item.optimize.map((opt) => opt.optimizeName),
            optimize: item.optimize.map((opt) => {
              if (opt.originalStatus === 1 || opt.optimizeStatus === 1 || opt.dataLinkageStatus === 1) {
                needInterval = true;
              }
              return {
                ...opt,
                planName: item.planName,
              };
            }),
          }));
          if (needInterval) {
            setTime(5000);
          } else {
            setTime(null);
          }
          setTableData(tableData);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTableLoading(false);
      });
  };

  // form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const optimizeDateCheck = (values) => {
    let optimizeValid = true;
    if (values.optimizeNames.includes('設計') && !(values.designOptimizeStartDate && values.designOptimizeEndDate)) {
      optimizeValid = false;
    }
    if (values.optimizeNames.includes('調達') && !(values.supplyOptimizeStartDate && values.supplyOptimizeEndDate)) {
      optimizeValid = false;
    }
    if (values.optimizeNames.includes('工事') && !(values.constructionOptimizeStartDate && values.constructionOptimizeEndDate)) {
      optimizeValid = false;
    }
    return optimizeValid;
  };

  const onFinish = (op, values: any) => {
    const valid = optimizeDateCheck(values);
    setOptimizeDateValid(valid);
    if (valid) {
      setBtnLoading(true);
      if (op === OPERATION.ADD) {
        APIList.createProjectPlan()
          .post({
            ...values,
            projectId: project,
            optimizeCrossSections: values.optimizeNames.map((optimizeName) => ({
              optimizeName,
              dataCrossSection: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designCrossSection;
                  case '調達':
                    return values.supplyCrossSection;
                  case '工事':
                    return values.constructionCrossSection;
                  default:
                    return null;
                }
              })(),
              optimizeStartDate: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designOptimizeStartDate;
                  case '調達':
                    return values.supplyOptimizeStartDate;
                  case '工事':
                    return values.constructionOptimizeStartDate;
                  default:
                    return null;
                }
              })(),
              optimizeEndDate: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designOptimizeEndDate;
                  case '調達':
                    return values.supplyOptimizeEndDate;
                  case '工事':
                    return values.constructionOptimizeEndDate;
                  default:
                    return null;
                }
              })(),
              optimizeLogic: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designOptimizeLogic;
                  case '調達':
                    return values.supplyOptimizeLogic;
                  case '工事':
                    return values.constructionOptimizeLogic;
                  default:
                    return null;
                }
              })(),
              referenceData: (() => {
                if (optimizeName == '設計') {
                  return values.referenceData;
                }
                return null;
              })()
            })),
          })
          .then(() => {
            message.success(t('aipskd.common.createMessage').replace('{placeholder}', t('aipskd.plan.plan')));
            handleCancel();
            getProjectPlans();
            setSelectOptions(null);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setBtnLoading(false);
          });
      }
      if (op === OPERATION.MODIFY) {
        APIList.updateProjectPlan()
          .put({
            ...values,
            projectId: project,
            optimizeCrossSections: values.optimizeNames.map((optimizeName) => ({
              optimizeName,
              dataCrossSection: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designCrossSection;
                  case '調達':
                    return values.supplyCrossSection;
                  case '工事':
                    return values.constructionCrossSection;
                  default:
                    return null;
                }
              })(),
              optimizeStartDate: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designOptimizeStartDate;
                  case '調達':
                    return values.supplyOptimizeStartDate;
                  case '工事':
                    return values.constructionOptimizeStartDate;
                  default:
                    return null;
                }
              })(),
              optimizeEndDate: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designOptimizeEndDate;
                  case '調達':
                    return values.supplyOptimizeEndDate;
                  case '工事':
                    return values.constructionOptimizeEndDate;
                  default:
                    return null;
                }
              })(),
              optimizeLogic: (() => {
                switch (optimizeName) {
                  case '設計':
                    return values.designOptimizeLogic;
                  case '調達':
                    return values.supplyOptimizeLogic;
                  case '工事':
                    return values.constructionOptimizeLogic;
                  default:
                    return null;
                }
              })(),
              referenceData: (() => {
                if (optimizeName == '設計') {
                  return values.referenceData;
                }
                return null;
              })()
            })),
          })
          .then(() => {
            message.success(t('aipskd.common.updateMessage').replace('{placeholder}', t('aipskd.plan.plan')));
            handleCancel();
            getProjectPlans();
            setSelectOptions(null);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setBtnLoading(false);
          });
      }
    }
  };

  const resetProjectStorage = (projectId) => {
    const dataCrossSection: any = {};
    dataCrossSection.project = projectId;
    setItem('dataCrossSection', JSON.stringify(dataCrossSection), 'local');
  };

  const getEndMinDate = () => {
    const projectInfo = projectList.find((item) => item.projectId === project);
    return projectInfo ? dayjs(projectInfo.endDate).subtract(2, 'months') : null;
  };

  const dataSnapshotChangeHandler = (snapshot, optimizeName) => {
    const dataSnapshots = projectList.find((item) => item.projectId === project).dataSnapshots;
    const dataSnapshot = dataSnapshots.find((item) => (item.snapshot === snapshot && item.optimizeName === optimizeName));
    if (optimizeName === '設計') {
      const designOptimizeStartDate = selectedOptDate.current[dataSnapshot.snapshot]?.designOptimizeStartDate;
      const designOptimizeEndDate = selectedOptDate.current[dataSnapshot.snapshot]?.designOptimizeEndDate;
      if (designOptimizeStartDate) {
        form.setFieldsValue({
          designOptimizeStartDate: dayjs(designOptimizeStartDate),
        });
      } else {
        form.setFieldsValue({
          designOptimizeStartDate: dataSnapshot.optStartDate ? dayjs(dataSnapshot.optStartDate) : null,
        });
      }
      if (designOptimizeEndDate) {
        form.setFieldsValue({
          designOptimizeEndDate: dayjs(designOptimizeEndDate),
        });
      } else {
        form.setFieldsValue({
          designOptimizeEndDate: dataSnapshot.optEndDate ? dayjs(dataSnapshot.optEndDate) : null,
        });
      }
    }
    if (optimizeName === '調達') {
      const supplyOptimizeStartDate = selectedOptDate.current[dataSnapshot.snapshot]?.supplyOptimizeStartDate;
      const supplyOptimizeEndDate = selectedOptDate.current[dataSnapshot.snapshot]?.supplyOptimizeEndDate;
      if (supplyOptimizeStartDate) {
        form.setFieldsValue({
          supplyOptimizeStartDate: dayjs(supplyOptimizeStartDate),
        });
      } else {
        form.setFieldsValue({
          supplyOptimizeStartDate: dataSnapshot.optStartDate ? dayjs(dataSnapshot.optStartDate) : null,
        });
      }
      if (supplyOptimizeEndDate) {
        form.setFieldsValue({
          supplyOptimizeEndDate: dayjs(supplyOptimizeEndDate),
        });
      } else {
        form.setFieldsValue({
          supplyOptimizeEndDate: dataSnapshot.optEndDate ? dayjs(dataSnapshot.optEndDate) : null,
        });
      }
    }
    if (optimizeName === '工事') {
      const constructionOptimizeStartDate = selectedOptDate.current[dataSnapshot.snapshot]?.constructionOptimizeStartDate;
      const constructionOptimizeEndDate = selectedOptDate.current[dataSnapshot.snapshot]?.constructionOptimizeEndDate;
      if (constructionOptimizeStartDate) {
        form.setFieldsValue({
          constructionOptimizeStartDate: dayjs(constructionOptimizeStartDate),
        });
      } else {
        form.setFieldsValue({
          constructionOptimizeStartDate: dataSnapshot.optStartDate ? dayjs(dataSnapshot.optStartDate) : null,
        });
      }
      if (constructionOptimizeEndDate) {
        form.setFieldsValue({
          constructionOptimizeEndDate: dayjs(constructionOptimizeEndDate),
        });
      } else {
        form.setFieldsValue({
          constructionOptimizeEndDate: dataSnapshot.optEndDate ? dayjs(dataSnapshot.optEndDate) : null,
        });
      }
    }
  };

  const dateChangeHandler = (date, dateKey, snapshot) => {
    const dataSnapshots = projectList.find((item) => item.projectId === project).dataSnapshots;
    const dataSnapshot = dataSnapshots.find((item) => item.snapshot === snapshot);
    selectedOptDate.current[dataSnapshot.snapshot] = {
      ...selectedOptDate.current[dataSnapshot.snapshot],
      [dateKey]: date,
    };
  };

  const reducer = (state, action) => {
    const minDate = getEndMinDate();
    switch (action.type) {
      case 'minDateInit':
        return {
          designMinDate: minDate,
          supplyMinDate: minDate,
          constructionMinDate: minDate,
        };
      case 'designStartDateChange':
        const designStartDate = form.getFieldValue('designOptimizeStartDate') ? dayjs(form.getFieldValue('designOptimizeStartDate')) : null;
        return {
          ...state,
          designMinDate: designStartDate ? (minDate.isAfter(designStartDate) ? minDate : designStartDate) : minDate,
        };
      case 'supplyStartDateChange':
        const supplyStartDate = form.getFieldValue('supplyOptimizeStartDate') ? dayjs(form.getFieldValue('supplyOptimizeStartDate')) : null;
        return {
          ...state,
          supplyMinDate: supplyStartDate ? (minDate.isAfter(supplyStartDate) ? minDate : supplyStartDate) : minDate,
        };
      case 'constructionStartDateChange':
        const constructionStartDate = form.getFieldValue('constructionOptimizeStartDate')
          ? dayjs(form.getFieldValue('constructionOptimizeStartDate'))
          : null;
        return {
          ...state,
          constructionMinDate: constructionStartDate ? (minDate.isAfter(constructionStartDate) ? minDate : constructionStartDate) : minDate,
        };
      case 'designEndDateChange':
        const designEndDate = form.getFieldValue('designOptimizeEndDate') ? dayjs(form.getFieldValue('designOptimizeEndDate')) : null;
        return {
          ...state,
          designMaxDate: designEndDate,
        };
      case 'supplyEndDateChange':
        const supplyEndDate = form.getFieldValue('supplyOptimizeEndDate') ? dayjs(form.getFieldValue('supplyOptimizeEndDate')) : null;
        return {
          ...state,
          supplyMaxDate: supplyEndDate,
        };
      case 'constructionEndDateChange':
        const constructionEndDate = form.getFieldValue('constructionOptimizeEndDate')
          ? dayjs(form.getFieldValue('constructionOptimizeEndDate'))
          : null;
        return {
          ...state,
          constructionMaxDate: constructionEndDate,
        };
    }
  };

  const [dateLimit, dispatch] = useReducer(reducer, {
    designMinDate: null,
    supplyMinDate: null,
    constructionMinDate: null,
    designMaxDate: null,
    supplyMaxDate: null,
    constructionMaxDate: null,
  });

  const designOptimizeDisabled = (dataSnapshots?) => {
    return (
      !epcDataSnapshotsMapping(dataSnapshots).Engineering.length ||
      planInfo?.optimize?.findIndex((item) => item.optimizeName === '設計') > -1
    );
  };

  const supplyOptimizeDisabled = (dataSnapshots?) => {
    return (
      !epcDataSnapshotsMapping(dataSnapshots).Procurement.length ||
      planInfo?.optimize?.findIndex((item) => item.optimizeName === '調達') > -1
    );
  };

  const constructionOptimizeDisabled = (dataSnapshots?) => {
    return (
      !epcDataSnapshotsMapping(dataSnapshots).Construction.length ||
      planInfo?.optimize?.findIndex((item) => item.optimizeName === '工事') > -1
    );
  };

  const selectOnlyOneForCheckbox = (list: string[]) => {
    // list.length > 1 && list.shift();
    if (list.length > 1) {
      const isDisabled = planInfo?.optimize?.findIndex((item) => item.optimizeName === list[0]) > -1
      !isDisabled && list.shift();
    }
  }

  return (
    <>
      <Card
        title={t('aipskd.plan.list')}
        className="list-card tree-table"
        styles={{
          header: { minHeight: 46, fontSize: 20, fontWeight: 900 },
        }}
        extra={dropdown}
      >
        <TableCard {...property} />
      </Card>
      <Modal
        title={t('aipskd.plan.delete')}
        open={isDeleteModalOpen}
        destroyOnClose
        onCancel={() => {
          handleDeleteCancel();
        }}
        maskClosable={false}
        footer={[
          <div key="deleteGroup" style={{ textAlign: 'center' }}>
            <Button key="cancel" onClick={handleDeleteCancel}>
              {t('common.cancel')}
            </Button>
            <Button
              key="delete"
              type="primary"
              onClick={() => handleDelete(deleteItem)}
              disabled={deleteDisabled}
              style={{ marginLeft: 30 }}
            >
              {t('common.delete')}
            </Button>
          </div>,
        ]}
      >
        <p style={{ whiteSpace: 'pre-line' }}>{t('aipskd.common.deleteConfirmation').replace('{placeholder}', namePlaceholder)}</p>
        <Input placeholder={namePlaceholder} value={inputName} onChange={handleInputChange} />
      </Modal>
      <Modal
        className="inputTableModal"
        width={window.innerWidth - 100}
        title={t('aipskd.plan.inputTables')}
        open={isInputTableModalOpen}
        destroyOnClose
        styles={{
          body: { height: window.innerHeight - 300 },
        }}
        onCancel={() => {
          handleInputTableClose();
        }}
        maskClosable={false}
        footer={null}
      >
        <div
          key="selectGroup"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Select
            key="selectTable"
            value={selectedTable}
            options={tableList}
            style={{ width: 300, zIndex: 10 }}
            onChange={handleTableChange}
          />
          <div>
            {t('aipskd.plan.inputRecords')}：{inputTableTotal}
            &nbsp;{t('aipskd.plan.recordsUnit')}
          </div>
        </div>
        <Table
          virtual
          className="virtual-grid super-small"
          key="inputVirtualTable"
          loading={inputTableLoading}
          size="small"
          columns={inputTableCol}
          dataSource={inputTableData}
          pagination={false}
          scroll={{ y: window.innerHeight - 430 }}
          rowClassName={(record, index) => {
            if (index === inputTableCol.length - 1) {
              return 'virtual-table-cell-last';
            }
            if (index % 2 === 0) {
              return 'virtual-table-cell-even';
            }
            if (index % 2 !== 0) {
              return 'virtual-table-cell-odd';
            }
          }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('aipskd.plan.nodata')} />,
          }}
        />
      </Modal>
      <Modal
        title={operation === OPERATION.ADD ? t('aipskd.plan.create') : t('aipskd.plan.update')}
        width={1000}
        destroyOnClose
        open={isModalOpen}
        onCancel={() => {
          handleCancel();
        }}
        maskClosable={false}
        footer={null}
      >
        <Form
          className="plan-form"
          form={form}
          {...layout}
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          onFinish={(values) => {
            onFinish(operation, values);
          }}
          onFieldsChange={(changedFields, allFields) => {
            setOptimizeDateValid(true);
          }}
        >
          {operation === OPERATION.MODIFY ? (
            <Form.Item name="planId" hidden rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item
            label={t('aipskd.plan.name')}
            name="planName"
            rules={[
              {
                required: true,
                message: t('aipskd.common.inputMessage'),
              },
            ]}
          >
            <Input placeholder={t('aipskd.common.inputPlaceholder').replace('{placeholder}', t('aipskd.plan.name'))} />
          </Form.Item>
          <Form.Item
            label={t('aipskd.plan.description')}
            name="planDescription"
            rules={[
              {
                required: true,
                message: t('aipskd.common.inputMessage'),
              },
            ]}
          >
            <Input.TextArea placeholder={t('aipskd.common.inputPlaceholder').replace('{placeholder}', t('aipskd.plan.description'))} />
          </Form.Item>
          <Form.Item
            label={t('aipskd.plan.stage')}
            name="stage"
            rules={[
              {
                required: true,
                message: t('aipskd.common.inputMessage'),
              },
            ]}
          >
            <Select
              placeholder={t('aipskd.common.inputPlaceholder').replace('{placeholder}', t('aipskd.plan.stage'))}
              options={stageOptions}
            />
          </Form.Item>
          <div>
            <div
              style={{
                display: 'flex',
                marginLeft: 200,
                background: '#fafafa',
                borderBottom: '1px solid #f0f0f0',
                height: 32,
                alignItems: 'center',
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  width: 60,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: 1,
                    height: 22,
                    position: 'absolute',
                    right: 0,
                    background: '#f0f0f0',
                  }}
                />
              </div>
              <div
                style={{
                  width: 120,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                {t('aipskd.plan.dataSnapshot')}
                <div
                  style={{
                    width: 1,
                    height: 22,
                    position: 'absolute',
                    right: 0,
                    background: '#f0f0f0',
                  }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                {t('aipskd.plan.optStartDate')}
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                {t('aipskd.plan.optEndDate')}
              </div>
              <div
                style={{
                  flex: 1.6,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 8px',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                {t('aipskd.plan.optimizeLogic')}
              </div>
            </div>
            <Form.Item label={<span>{t('aipskd.plan.optimizationTarget')}</span>} required>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 60 }}>
                  <Form.Item
                    name="optimizeNames"
                    rules={[
                      {
                        required: true,
                        message: t('aipskd.common.inputMessage'),
                      },
                    ]}
                  >
                    <Checkbox.Group style={{ width: '100%', height: '155px' }} onChange={selectOnlyOneForCheckbox}>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <Checkbox
                            value="工事"
                            disabled={
                              !epcDataSnapshotsMapping().Construction.length ||
                              planInfo?.optimize?.findIndex((item) => item.optimizeName === '工事') > -1
                            }
                            style={{ lineHeight: '32px', width: 80 }}
                          >
                            {t('aipskd.plan.construction')}
                          </Checkbox>
                        </div>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                          <Checkbox
                            value="調達"
                            disabled={
                              !epcDataSnapshotsMapping().Procurement.length ||
                              planInfo?.optimize?.findIndex((item) => item.optimizeName === '調達') > -1
                            }
                            style={{ lineHeight: '32px', width: 80 }}
                          >
                            {t('aipskd.plan.procurement')}
                          </Checkbox>
                        </div>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                          <Checkbox
                            value="設計"
                            disabled={
                              !epcDataSnapshotsMapping().Engineering.length ||
                              planInfo?.optimize?.findIndex((item) => item.optimizeName === '設計') > -1
                            }
                            style={{ lineHeight: '32px', width: 80 }}
                          >
                            {t('aipskd.plan.engineering')}
                          </Checkbox>
                        </div>
                      </div>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div style={{ width: 700 }}>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      name="constructionCrossSection"
                      style={{ margin: 0, marginRight: 10, width: 130 }}
                    >
                      <Select
                        style={{ flex: 1, width: 115 }}
                        disabled={constructionOptimizeDisabled()}
                        options={epcDataSnapshotsMapping().Construction}
                        placeholder={t('aipskd.plan.dataSnapshot')}
                        onChange={(snapshot) => {
                          dataSnapshotChangeHandler(snapshot, '工事');
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="constructionOptimizeStartDate" style={{ marginBottom: 0 }}>
                      <DatePicker
                        disabled={constructionOptimizeDisabled()}
                        onChange={(date) => {
                          dateChangeHandler(date, 'constructionOptimizeStartDate', form.getFieldValue('constructionCrossSection'));
                          dispatch({ type: 'constructionStartDateChange' });
                        }}
                        maxDate={dateLimit.constructionMaxDate}
                      />
                    </Form.Item>
                    <Form.Item name="constructionOptimizeEndDate" style={{ marginBottom: 0 }}>
                      <DatePicker
                        style={{ marginLeft: 5 }}
                        disabled={constructionOptimizeDisabled()}
                        onChange={(date) => {
                          dateChangeHandler(date, 'constructionOptimizeEndDate', form.getFieldValue('constructionCrossSection'));
                          dispatch({ type: 'constructionEndDateChange' });
                        }}
                        minDate={dateLimit.constructionMinDate}
                      />
                    </Form.Item>
                    <Form.Item name="constructionOptimizeLogic" style={{ marginBottom: 0 }}>
                      <Radio.Group style={{ marginLeft: 15 }} disabled={constructionOptimizeDisabled()}>
                        <Flex style={{ width: 250 }}>
                          <Radio value="Forward" style={{ flex: 1, marginInlineEnd: 0 }}>
                            Forward
                          </Radio>
                          <Radio value="Backward(Std.)" style={{ flex: 1 }}>
                            Backward(Std.)
                          </Radio>
                        </Flex>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      name="supplyCrossSection"
                      style={{
                        marginTop: 10,
                        marginBottom: 0,
                        marginRight: 10,
                        width: 130,
                      }}
                    >
                      <Select
                        style={{ flex: 1, width: 115 }}
                        disabled={supplyOptimizeDisabled()}
                        options={epcDataSnapshotsMapping().Procurement}
                        placeholder={t('aipskd.plan.dataSnapshot')}
                        onChange={(snapshot) => {
                          dataSnapshotChangeHandler(snapshot, '調達');
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="supplyOptimizeStartDate" style={{ marginTop: 10, marginBottom: 0 }}>
                      <DatePicker
                        disabled={supplyOptimizeDisabled()}
                        onChange={(date) => {
                          dateChangeHandler(date, 'supplyOptimizeStartDate', form.getFieldValue('supplyCrossSection'));
                          dispatch({ type: 'supplyStartDateChange' });
                        }}
                        maxDate={dateLimit.supplyMaxDate}
                      />
                    </Form.Item>
                    <Form.Item name="supplyOptimizeEndDate" style={{ marginTop: 10, marginBottom: 0 }}>
                      <DatePicker
                        style={{ marginLeft: 5 }}
                        disabled={supplyOptimizeDisabled()}
                        onChange={(date) => {
                          dateChangeHandler(date, 'supplyOptimizeEndDate', form.getFieldValue('supplyCrossSection'));
                          dispatch({ type: 'supplyEndDateChange' });
                        }}
                        minDate={dateLimit.supplyMinDate}
                      />
                    </Form.Item>
                    <Form.Item name="supplyOptimizeLogic" style={{ marginTop: 10, marginBottom: 0 }}>
                      <Radio.Group style={{ marginLeft: 15 }} disabled={supplyOptimizeDisabled()}>
                        <Flex style={{ width: 250 }}>
                          <Radio value="Forward" style={{ flex: 1, marginInlineEnd: 0 }}>
                            Forward
                          </Radio>
                          <Radio value="Backward" style={{ flex: 1 }}>
                            Backward
                          </Radio>
                        </Flex>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      name="designCrossSection"
                      style={{
                        marginTop: 10,
                        marginBottom: 0,
                        marginRight: 10,
                        width: 130,
                      }}
                    >
                      <Select
                        style={{ flex: 1, width: 115 }}
                        disabled={designOptimizeDisabled()}
                        options={epcDataSnapshotsMapping().Engineering}
                        placeholder={t('aipskd.plan.dataSnapshot')}
                        onChange={(snapshot) => {
                          dataSnapshotChangeHandler(snapshot, '設計');
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="designOptimizeStartDate" style={{ marginTop: 10, marginBottom: 0 }}>
                      <DatePicker
                        disabled={designOptimizeDisabled()}
                        onChange={(date) => {
                          dateChangeHandler(date, 'designOptimizeStartDate', form.getFieldValue('designCrossSection'));
                          dispatch({ type: 'designStartDateChange' });
                        }}
                        maxDate={dateLimit.designMaxDate}
                      />
                    </Form.Item>
                    <Form.Item name="designOptimizeEndDate" style={{ marginTop: 10, marginBottom: 0 }}>
                      <DatePicker
                        style={{ marginLeft: 5 }}
                        disabled={designOptimizeDisabled()}
                        onChange={(date) => {
                          dateChangeHandler(date, 'designOptimizeEndDate', form.getFieldValue('designCrossSection'));
                          dispatch({ type: 'designEndDateChange' });
                        }}
                        minDate={dateLimit.designMinDate}
                      />
                    </Form.Item>
                    <Form.Item name="designOptimizeLogic" style={{ marginTop: 10, marginBottom: 0 }}>
                      <Radio.Group style={{ marginLeft: 15 }} disabled={designOptimizeDisabled()}>
                        <Flex style={{ width: 250 }}>
                          <Radio value="Forward(Std.)" style={{ flex: 1, marginInlineEnd: 0 }}>
                            Forward(Std.)
                          </Radio>
                          <Radio value="Backward" style={{ flex: 1 }}>
                            Backward
                          </Radio>
                        </Flex>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      label={t('aipskd.plan.referenceData')}
                      name="referenceData"
                      style={{ marginTop: 10, marginBottom: 0, marginRight: 10, width: '100%' }}
                    >
                      <Select
                        style={{ flex: 1, width: '100%' }}
                        disabled={designOptimizeDisabled()}
                        options={referenceOptions}
                        placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.select.optionName'))}
                        allowClear={true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              {!optimizeDateValid ? (
                <div
                  style={{
                    color: 'rgb(221, 107, 102)',
                    position: 'absolute',
                    bottom: 0,
                  }}
                >
                  <span>{t('aipskd.common.inputMessage')}</span>
                </div>
              ) : (
                <></>
              )}
            </Form.Item>
          </div>
          <Form.Item {...tailLayout}>
            <Button
              htmlType="button"
              onClick={() => {
                handleCancel();
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button style={{ marginLeft: 30 }} type="primary" htmlType="submit" loading={btnLoading}>
              {t('common.submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProjectPlan;
