import { useEffect, useState } from "react";
import { TableChart } from '@meteor/frontend-core';
import { chain, eq, forEach, has, head, isEmpty, isNil, last, omitBy, set, values } from 'lodash';
import { getSizeByMap } from "../../utils/commonUtil";
import dayjs from "dayjs";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib";

type ChartProps = {
  data?: any[];
  loading?: boolean;
  sizeMode?: 'small' | 'big',
  height?: number;
  axis?: string[],
};

const sizeMap = {
  small: { title: 18 },
  big: { title: 28 },
};

// echart line color and line-style mapping
const LegendColorMap = {
  // 'Pipe MTO':{color: '#8ca8db'},
  // 'Support MTO':{color: '#f4b285'},
  // 'Pipe Delivery':{color: '#3f6ec3', lineStyle: 'dashed'},
  // 'Support Delivery':{color: '#ed7d32', lineStyle: 'dashed'},
  // 'ISO ME':{color: '#4774c5'},
  // 'Special Support DWG':{color: '#ed7a2c'},
  'Fabrication Spool': { color: '#1A4F99' },
  'Fabrication Support': { color: '#3299D9' },
  'Installation Spool': { color: '#9FD9F6', lineStyle: 'dashed' },
  'Installation Support': { color: '#093B5E', lineStyle: 'dashed' },
}

// handle for radio button
const handleRadioOptions = (target: { [key: string]: any[] }) =>
  chain(target).keys()
    .map((item) => ({ label: item, value: item }))
    .value();

// handle data for legend options value
// const handleDataForLegend = (target: any[]): string[] =>
//   isEmpty(target) ? [] : chain(target)
//     .map('workfrontCategory').compact().uniq().value();

// handle data for xAxis options type
export const handleDataForXAxis = (target: any[], defaultAxis: string[] = []): any[] => {
  // return isEmpty(target) ? [...defaultAxis]: chain(target).map('day').uniq().sort().value();
  if (isEmpty(target) || !isEmpty(defaultAxis)) {
    return [...defaultAxis];
  }
  const days = chain(target).map('day').uniq().sort().value();
  const startDay = head(days);
  const endDay = last(days);
  const result = [];
  let current = dayjs(startDay);
  while(current.isBefore(endDay) || current.isSame(endDay)) {
    result.push(current.format('YYYY-MM-DD'));
    current = current.add(1, 'day');
  }
  return result;
}

const handleDataForSeries = (target: any[], defaultAxis: string[] = []): object => {
  if (isEmpty(target)) {
    // return map(LegendColorMap, (_, name) => ({ name, data: [] }));
    return [];
  }
  const targetDate = handleDataForXAxis(target, defaultAxis);
  return chain(target)
    // { 'Fabrication Spool': { '2024-01-01': '1000', '2024-01-02': '1633' } }
    // 按照`workfrontName`分类　& 调节数值
    .reduce((result, item) => {
      if (!result[item.workfrontName]) {
        result[item.workfrontName] = {};
      }
      // 数值调节在此处
      set(result[item.workfrontName], item.day, Math.round(item.value).toLocaleString());
      return result;
    }, {})
    // 数据处理: 补全缺失日
    .reduce((result, item, name) => {
      result[name] = {};
      // item is { 2026-06-19: 100, 2026-06-20: 1400 }
      forEach(targetDate, (day) => {
        // 缺失时将缺失日期设置为null，（当前业务线断掉）
        // 当前缺失部分设置为null，如果需要设置其它值，修改此处即可
        // 如果取前一天可以get当前day-1的日期，不为null则为当前值，反之设置为null
        result[name][day] = !has(item, day) ? null: item[day];
      });
      return result;
    }, {})
    .map((item, name) =>
      omitBy({
        name, type: 'line', color: LegendColorMap[name]?.color,
        lineStyle: LegendColorMap[name]?.lineStyle ? { type: LegendColorMap[name]?.lineStyle }: null,
        symbol: 'none',
        data: values(item),
      }, isNil)
    )
    .value();
}

const SpoolWorkfrontChart: React.FC<ChartProps> = (props) => {
  const { data, loading, sizeMode = 'small', height, axis } = props;
  const title = {
    value: 'Workfront Management',
    styles: {
      fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
      paddingLeft: sizeMode === 'big' ? 15 : 0,
    },
  };
  const getHeight = () => (sizeMode === 'big' ? 180 + (height - 322) - 10 : 180) + 108;
  const [options, setOptions] = useState<any>({ series: handleDataForSeries([]) });
  // { Spool: [], Support: [] }
  const [typeMapping, setTypeMapping] = useState<{ [key: string]: any[] }>({});
  const [radioValue, setRadioValue] = useState<string>();
  // 添加此Effect为了保证data不变下，`TypeMapping`只计算一次
  useEffect(() => {
    if (!isEmpty(data)) {
      const toMapping = chain(data).groupBy('workfrontCategory').value();
      setTypeMapping(toMapping);
      // 初始化设置值
      setRadioValue(chain(toMapping).keys().get(0).value());
    }
  }, [data]);
  // 更新EChart实际配置
  useEffect(() => {
    if (radioValue && !isEmpty(typeMapping)) {
      const source = typeMapping[radioValue];
      setOptions({
        grid: { bottom: 20 },
        tooltip: { trigger: 'axis', appendToBody: true },
        xAxis: {
          data: handleDataForXAxis(source, axis),
        },
        legend: { type: 'scroll' },
        // legend: { data: handleDataForLegend(source) },
        yAxis: {
          type: 'value', name: `[${source?.[0].unit}]`,
          nameTextStyle: {
            color: "#aaa",
            nameLocation: "start",
          },
        },
        series: handleDataForSeries(source, axis)
      });
    }
  }, [radioValue, typeMapping]);
  return (
    <>
      <TableChart
        select={{
          type: 'switches',
          node: <Radio.Group
            options={handleRadioOptions(typeMapping)}
            value={radioValue}
            onChange={({ target: { value } }: RadioChangeEvent) => { setRadioValue(value) }}
            optionType="button"
            buttonStyle="solid"
            size="small"
          />
        }}
        title={title}
        tooltips={['zoomIn']}
        chartOptions={options}
        height={getHeight()}
        loading={loading}
        isBank={!data || data.length === 0}
      />
    </>
  );
}

export default SpoolWorkfrontChart;
