import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react';
import { CheckColumn, Column, ColumnStore, DateHelper, Gantt, PresetManager, ProjectModel, Store } from '@bryntum/gantt';
import { cloneDeep, isNil, round, uniq } from 'lodash';
import AlertTaskContext from '../contexts/AlertTaskContext';
import { getRandomString } from '../utils/commonUtil';
import '@bryntum/gantt/gantt.stockholm.css';
import { App, Button, Card, Col, Flex, Modal, Row, Space, Switch, Table } from 'antd';
import { getDayString } from '../utils/DateUtil';
import { CloseOutlined, CloseSquareOutlined } from '@ant-design/icons';
import APIList from '../http/ApiList';
import GanttSubChart from './bryntum-gantt-sub';
import RelatedPathContext from '../contexts/RelatedPathContext';
import PathMpPeak from './path-mp-peak';
import { useTranslation } from 'react-i18next';

PresetManager.registerPreset('monthAndYear', {
  base: 'monthAndYear',
  tickWidth: 70, // 设置宽度
  headers: [
    {
      unit: 'YEAR',
      align: 'center',
      dateFormat: 'Y',
    },
    {
      unit: 'MONTH',
      align: 'center',
      dateFormat: 'MMM',
    },
  ],
});

const ganttConfig: BryntumGanttProps = {
  selectionMode: {
    multiSelect: false,
    dragSelect: false,
  },
  // appendTo: 'main',
  // flex: '1 1 auto',
  scrollTaskIntoViewOnCellClick: true,
  subGridConfigs: {
    fixed: {
      // No resizing of the fixed column region
      resizable: false,
      // Set a lower weight than the built-in "locked" section to have it appear to the left
      weight: 1,
    },
    locked: {
      // A scrollable section with the main columns
      width: 320,
      weight: 2,
    },
  },
  columns: [
    {
      type: 'check',
      field: 'check',
      text: 'Pick',
      width: 80,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: {
            true: 'Checked',
            false: 'UN Checked',
          },
        },
      },
      checkCls: 'bryntum-column-check',
    },
    { type: 'name', field: 'name', width: 320, editor: false },
    {
      text: 'Activity ID',
      field: 'taskId',
      width: 160,
      renderer: (task) => `${task.record.taskId || '-'}`,
      editor: false,
    },
    {
      text: 'wbs',
      field: 'wbs',
      renderer: (task) => `${task.record.wbs || '-'}`,
      editor: false,
    },
    {
      text: 'discipline',
      field: 'discipline',
      renderer: (task) => `${task.record.discipline || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
      editor: false,
    },
    {
      text: 'CWA',
      field: 'area',
      renderer: (task) => `${task.record.area || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
      editor: false,
    },
    {
      text: 'package',
      field: 'package',
      renderer: (task) => `${task.record.package || '-'}`,
      sortable: false,
      filterable: {
        filterField: {
          type: 'combo',
          multiSelect: false,
          editable: false,
          items: [],
        },
      },
      editor: false,
    },
    { type: 'startdate', editor: false },
    { type: 'enddate', editor: false },
    { type: 'duration', editor: false },
    { type: 'predecessor', editor: false },
  ],
  barMargin: 5,
  rowHeight: 20,
  cls: 'gantt-bar',
  project: {
    // startDate: '2023-03-01',
    // endDate: '2024-12-09',
    taskStore: {
      fields: [
        'check',
        'name',
        'taskId',
        'wbs',
        'discipline',
        'area',
        'package',
        'startdate',
        'enddate',
        'duration',
        'predecessor',
        'fwbsLevel',
        'cstrDate',
        'mpPerDay',
      ],
    },
    // transport: {
    //   load: {
    //     // url: '/api/schedule-tasks',
    //     url: '../data/gantt-data.json',
    //   },
    // },
    // autoLoad: false,
    // The State TrackingManager which the UndoRedo widget in the toolbar uses
    stm: {
      autoRecord: true,
    },
    // Reset Undo / Redo after each load
    resetUndoRedoQueuesAfterLoad: true,
    // calendarsData: [
    //   {
    //     id: 'general',
    //     name: 'General',
    //     intervals: [
    //       {
    //         recurrentStartDate: 'on Sat at 0:00',
    //         recurrentEndDate: 'on Mon at 0:00',
    //         isWorking: false,
    //       },
    //     ],
    //   },
    // ],
  },
  viewPreset: 'monthAndYear',
  dependenciesFeature: {
    allowCreate: false,
    drawOnScroll: false,
  },
  percentBarFeature: {
    allowResize: false,
  },
  taskEditFeature: false,
  taskMenuFeature: false,
  taskDragFeature: false,
  taskResizeFeature: false,
  taskDragCreateFeature: false,
  criticalPathsFeature: {
    disabled: true,
  },
  filterFeature: true,
  progressLineFeature: {
    disabled: true,
  },
  taskNonWorkingTimeFeature: true,
  baselinesFeature: {
    disabled: false,
    template: (item): string => {
      const { baseline } = item;
      const { task } = baseline;

      const displayDuration = baseline.duration;

      return `<div class="b-gantt-task-title">
              ${task.name} (${baseline.name})</div>
              <table>
                <tr>
                  <td>Start:</td>
                  <td>${item.startClockHtml}</td>
                </tr>
                <tr>
                  <td>End:</td>
                  <td>${item.endClockHtml}</td>
                </tr>
                <tr>
                  <td>Duration:</td>
                  <td class="b-right">${`${displayDuration} ${DateHelper.getLocalizedNameOfUnit(
                    baseline.durationUnit,
                    baseline.duration !== 1
                  )}`}</td>
                </tr>
              </table>
              `;
    },
  },
  timeRangesFeature: true,
  indicatorsFeature: {
    items: {
      earlyDates: false,
      lateDates: false,
      deadlineDate: false,
      constraintDate: false,
      cstrDateIndicator: (taskRecord) =>
        taskRecord.cstrDate && ['MIL-', 'ROS-', 'ERD-'].some((startKey) => taskRecord.taskId.startsWith(startKey))
          ? {
              startDate: new Date(taskRecord.cstrDate),
              name: 'Constraint Date',
              iconCls: 'b-cus-diamond',
            }
          : null,
    },
    tooltipTemplate: (data) => {
      console.log(data);
      const {
        indicator: {
          taskRecord: { name, startDate, endDate, duration, percentDone },
        },
        startText,
      } = data;
      return `<div>
        <div style="font-weight: bolder">${name}</div>
        <div style="display: flex; justify-content: space-between;">
          <div>
            ${!isNil(startDate) ? `<div>Start: </div>` : ''} 
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>End: </div>` : ''}
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>Duration: </div>` : ''}
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>Complete: </div>` : ''}
            <div>Constraint: </div>
          </div>
          <div style="margin-left: 5px; text-align: right">
            ${!isNil(startDate) ? `<div>${DateHelper.format(startDate, 'MMM DD, YYYY')}</div>` : ''} 
            ${
              !isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${DateHelper.format(endDate, 'MMM DD, YYYY')}</div>` : ''
            } 
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${round(duration, 1)} days</div>` : ''}
            ${!isNil(endDate) && startDate.getTime() !== endDate.getTime() ? `<div>${percentDone * 100}%</div>` : ''}
            <div>${startText}</div>
          </div>
        </div>
      </div>`;
    },
  },
  // pdfExportFeature: {
  //   exportServer: 'http://localhost:8080',
  //   translateURLsToAbsolute: 'http://localhost:3000',
  //   clientURL: 'http://localhost:3000',
  //   keepPathName: false,
  // },
};

const GanttChart: React.FC<any> = (props) => {
  const { t } = useTranslation();

  const { message } = App.useApp();
  const { height, data, modeOperate, saveActiveTask, optimize, optimizationId, calenderOrg } = props;
  const [mode, modeDispatch] = modeOperate;
  const gantt = useRef<any>(null);
  const ganttSub = useRef<any>(null);
  const [ganttKey, setGanttKey] = useState<string>();
  const { alertView, setAlertView } = useContext(AlertTaskContext);

  const ganttInstance = (): Gantt => gantt.current?.instance;
  const ganttProject = (): ProjectModel => ganttInstance().project;
  const ganttStore = (): Store => ganttInstance().store as Store;
  const ganttColumns = () => ganttInstance().columns as ColumnStore;

  const [selected, setSelected] = useState<any>();

  const projectReady = useRef(false);

  const [relatedPathOpen, setRelatedPathOpen] = useState(false);
  const [drivingOnly, setDrivingOnly] = useState(false);
  const [subGanttModalReady, setSubGanttModalReady] = useState(false);

  const [calenderData, setCalenderData] = useState<any[]>();

  const onFeaturesClick = ({ source: item }) => {
    if (item.feature) {
      const feature = ganttInstance().features[item.feature];
      feature.disabled = !feature.disabled;
    } else if (item.subGrid) {
      const subGrid = ganttInstance().subGrids[item.subGrid];
      subGrid.collapsed = !subGrid.collapsed;
    } else if (item.toggleConfig) {
      ganttInstance[item.toggleConfig] = item.checked;
    }
  };

  const onFeaturesShow = ({ source: menu }) => {
    menu.items.map((item) => {
      const { feature } = item;

      if (feature) {
        // a feature might be not presented in the gantt
        // (the code is shared between "advanced" and "php" demos which use a bit different set of features)
        if (ganttInstance().features[feature]) {
          item.checked = !ganttInstance().features[feature].disabled;
        } else {
          // hide not existing features
          item.hide();
        }
      } else if (item.subGrid) {
        item.checked = ganttInstance().subGrids[item.subGrid].collapsed;
      }
    });
  };

  const onSettingsShow = ({ source: menu }) => {
    const { rowHeight, barMargin } = menu.widgetMap;

    rowHeight.value = ganttInstance().rowHeight;
    barMargin.value = ganttInstance().barMargin;
    barMargin.max = ganttInstance().rowHeight / 2 - 5;
  };

  const onRowHeightChange = ({ value, source }) => {
    ganttInstance().rowHeight = value;
    source.owner.widgetMap.barMargin.max = value / 2 - 5;
  };

  const onBarMarginChange = ({ value }) => {
    ganttInstance().barMargin = value;
  };

  const [ganttCardConfig, setGanttCardConfig] = useState(ganttConfig);

  const scrollToTask = (record) => {
    ganttInstance()
      .expandTo(record)
      .then(() => {
        try {
          ganttInstance()
            .scrollTaskIntoView(record, {
              animate: true,
              block: 'center',
              highlight: true,
              focus: true,
            })
            .then(() => {
              ganttInstance().selectRow(record);
            });
        } catch (error) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    if (!alertView || alertView.tab !== 'schedule' || !alertView.key) {
      return;
    }
    if (alertView.zoom) {
      ganttInstance().zoomTo({
        preset: 'monthAndYear',
        centerDate: new Date(alertView.startDate),
      });
      // ganttInstance().highlightTimeSpans([
      //   {
      //     startDate: new Date(alertView.startDate),
      //     endDate: new Date(alertView.endDate),
      //   },
      // ]);
    } else {
      const recordIndex = ganttStore().allRecords.findIndex((item: any) => item.taskId === alertView.key);
      if (recordIndex > -1) {
        scrollToTask(ganttStore().allRecords[recordIndex]);
      }
    }
  }, [alertView]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setGanttKey(getRandomString());
  }, [data]);

  useEffect(() => {
    setGanttCardConfig({
      ...ganttConfig,
      tbar: {
        items: [
          {
            type: 'combo',
            text: 'FWBS Lv',
            editable: false,
            value: '',
            items: {
              '': t('aipskd.optionDetail.all'),
              'FWBS Level 1': 'FWBS Level 1',
              'FWBS Level 4': 'FWBS Level 4',
              'FWBS Level 5': 'FWBS Level 5',
            },
            onSelect: ({ source, record, records, userAction }) => {
              if (record.value) {
                ganttInstance().taskStore.filter({
                  filters: (task) => {
                    return task.fwbsLevel === record.value;
                  },
                  replace: true,
                });
              } else {
                ganttInstance().taskStore.clearFilters();
              }
            },
          },
          {
            type: 'buttonGroup',
            items: [
              {
                ref: 'expandAllButton',
                icon: 'b-fa b-fa-angle-double-down',
                tooltip: 'Expand all',
                onAction: () => {
                  ganttInstance().expandAll();
                },
              },
              {
                ref: 'collapseAllButton',
                icon: 'b-fa b-fa-angle-double-up',
                tooltip: 'Collapse all',
                onAction: () => {
                  ganttInstance().collapseAll();
                },
              },
            ],
          },
          {
            type: 'buttonGroup',
            items: [
              {
                ref: 'zoomInButton',
                icon: 'b-fa b-fa-search-plus',
                tooltip: 'Zoom in',
                onAction: () => {
                  ganttInstance().zoomIn();
                },
              },
              {
                ref: 'zoomOutButton',
                icon: 'b-fa b-fa-search-minus',
                tooltip: 'Zoom out',
                onAction: () => {
                  ganttInstance().zoomOut();
                },
              },
              {
                ref: 'zoomToFitButton',
                icon: 'b-fa b-fa-compress-arrows-alt',
                tooltip: 'Zoom to fit',
                onAction: () => {
                  ganttInstance().zoomTo({
                    preset: 'monthAndYear',
                  });
                },
              },
              {
                ref: 'previousButton',
                icon: 'b-fa b-fa-angle-left',
                tooltip: 'Previous time span',
                onAction: () => {
                  ganttInstance().shiftPrevious();
                },
              },
              {
                ref: 'nextButton',
                icon: 'b-fa b-fa-angle-right',
                tooltip: 'Next time span',
                onAction: () => {
                  ganttInstance().shiftNext();
                },
              },
            ],
          },
          {
            type: 'button',
            ref: 'featuresButton',
            icon: 'b-fa b-fa-tasks',
            text: 'Settings',
            tooltip: 'Toggle features',
            menu: {
              onItem: onFeaturesClick,
              onBeforeShow: onFeaturesShow,
              // "checked" is set to a boolean value to display a checkbox for menu items. No matter if it is true or false.
              // The real value is set dynamically depending on the "disabled" config of the feature it is bound to.
              items: [
                {
                  text: 'UI settings',
                  icon: 'b-fa-sliders-h',
                  menu: {
                    cls: 'settings-menu',
                    layoutStyle: {
                      flexDirection: 'column',
                    },
                    onBeforeShow: onSettingsShow,
                    defaults: {
                      type: 'slider',
                      showValue: true,
                    },
                    items: [
                      {
                        ref: 'rowHeight',
                        text: 'Row height',
                        min: 20,
                        max: 70,
                        onInput: onRowHeightChange,
                      },
                      {
                        ref: 'barMargin',
                        text: 'Bar margin',
                        min: 0,
                        max: 10,
                        onInput: onBarMarginChange,
                      },
                    ],
                  },
                },
                {
                  text: 'Draw relations',
                  feature: 'dependencies',
                  checked: false,
                },
                {
                  text: 'Project lines',
                  feature: 'projectLines',
                  checked: false,
                },
                {
                  text: 'Show column lines',
                  feature: 'columnLines',
                  checked: true,
                },
                {
                  text: 'Hide schedule',
                  cls: 'b-separator',
                  subGrid: 'normal',
                  checked: false,
                },
              ],
            },
          },
          {
            type: 'button',
            text: 'Save',
            tooltip: 'Save Check Status',
            toggleable: true,
            onAction: (event) => {
              if (event.source.pressed) {
                saveActiveTask(
                  ganttStore()
                    .allRecords.filter((record: any) => record.check)
                    .map((record: any) => record.id)
                );
              }
            },
          },
          {
            key: 'relatedPath',
            type: 'button',
            text: 'Related Path',
            tooltip: 'Related Path',
            cls: 'related-path-btn',
            disabled: true,
            onAction: (event) => {
              setDrivingOnly(false);
              setRelatedPathOpen(true);
            },
          },
        ],
      },
    });
  }, [optimizationId]);

  useEffect(() => {
    if (!gantt.current || !ganttKey) {
      return;
    }
    const setCheck = (tasks, checked = false) => {
      tasks.forEach((task) => {
        if (task.set) {
          task.set('check', checked);
        } else {
          task.check = checked;
        }
        if (task.children) {
          setCheck(task.children, checked);
        }
      });
    };

    const toggleEventHandler = ({ source, record, checked }) => {
      if (record.children) {
        setCheck(record.children, checked);
      }
    };
    ganttColumns().get('check').on('toggle', toggleEventHandler);
    ganttInstance().on('taskClick', ({ source, taskRecord, event }) => {
      console.log(taskRecord);
      taskClickHandler({ source, taskRecord, event });
    });
    ganttInstance().on(
      'selectionChange',
      ({ action, mode, source, deselected, selected, selecttion, deselectedCells, selectedCells, cellSelection }) => {
        const tbar = ganttInstance().tbar.items as any[];
        const relatedPathBtn = tbar.find((item) => item.key === 'relatedPath');
        relatedPathBtn.disabled = ganttInstance().selectedRecords.length !== 1;
        if (mode === 'row') {
          setSelected(selected[0]);
        }
      }
    );
    projectReady.current = false;
    ganttProject().on({
      dataReady({ records }) {
        projectReady.current = true;
      },
    });
    ganttProject()
      .loadInlineData(data)
      .then(() => {
        setTimeout(() => {
          if (gantt && gantt.current && ganttInstance) {
            const finalData = [];
            getDataFromTree(finalData, data.tasksData);
            const disciplineColumn: any = ganttColumns().get('discipline');
            disciplineColumn.filterable.filterField.items = uniq(
              finalData.filter((item) => item.discipline).map((item) => item.discipline)
            );
            const areaColumn: any = ganttColumns().get('area');
            areaColumn.filterable.filterField.items = uniq(finalData.filter((item) => item.area).map((item) => item.area));
            const packageColumn: any = ganttColumns().get('package');
            packageColumn.filterable.filterField.items = uniq(finalData.filter((item) => item.package).map((item) => item.package));
          }
        }, 1000);
      });
  }, [ganttKey]);

  const getDataFromTree = (finalData: any[], treeData: any[]) => {
    treeData.forEach((treeNode) => {
      if (treeNode.children && treeNode.children.length > 0) {
        getDataFromTree(finalData, treeNode.children);
      } else {
        finalData.push(treeNode);
      }
    });
  };

  const toTaskPosition = (taskId) => {
    setAlertView({
      key: taskId,
      tab: 'schedule',
    });
  };

  const [predecessorColumns] = useState([
    {
      key: 'Predecessor',
      title: 'Predecessor',
      children: [
        {
          key: 'PredecessorTaskId',
          dataIndex: 'taskId',
          title: t('aipskd.optionDetail.taskId'),
          ellipsis: true,
          width: 130,
          render: (text, record, index) => (
            <Button
              size="small"
              style={{ padding: 0 }}
              type="link"
              className="link-ellipsis"
              onClick={() => {
                toTaskPosition(text);
              }}
            >
              {text}
            </Button>
          ),
        },
        {
          key: 'PredecessorTaskName',
          dataIndex: 'taskName',
          title: t('aipskd.optionDetail.taskName'),
          ellipsis: true,
          width: 180,
        },
        {
          key: 'PredecessorStartDate',
          dataIndex: 'startDate',
          title: t('aipskd.optionDetail.taskStartDate'),
          ellipsis: true,
          width: 100,
        },
        {
          key: 'PredecessorEndDate',
          dataIndex: 'endDate',
          title: t('aipskd.optionDetail.taskEndDate'),
          ellipsis: true,
          width: 100,
        },
        {
          key: 'PredecessorDependencyType',
          dataIndex: 'dependencyType',
          title: 'Ralation',
          ellipsis: true,
          width: 70,
        },
        {
          key: 'PredecessorLag',
          dataIndex: 'lag',
          title: 'Lag',
          ellipsis: true,
          width: 60,
        },
        {
          key: 'PredecessorFreeFloat',
          dataIndex: 'freeFloat',
          title: 'Free Float',
          ellipsis: true,
        },
        {
          key: 'PredecessorDriving',
          dataIndex: 'driving',
          title: 'Driving',
          ellipsis: true,
          filterMultiple: false,
          filters: [
            {
              text: 1,
              value: 1,
            },
            {
              text: 0,
              value: 0,
            },
          ],
          onFilter: (value, record) => record.driving === value,
        },
      ],
    },
  ]);
  const [successorColumns] = useState([
    {
      key: 'Successor',
      title: 'Successor',
      children: [
        {
          key: 'SuccessorTaskId',
          dataIndex: 'taskId',
          title: t('aipskd.optionDetail.taskId'),
          ellipsis: true,
          width: 130,
          render: (text, record, index) => (
            <Button
              size="small"
              style={{ padding: 0 }}
              type="link"
              className="link-ellipsis"
              onClick={() => {
                toTaskPosition(text);
              }}
            >
              {text}
            </Button>
          ),
        },
        {
          key: 'SuccessorTaskName',
          dataIndex: 'taskName',
          title: t('aipskd.optionDetail.taskName'),
          ellipsis: true,
          width: 180,
        },
        {
          key: 'SuccessorStartDate',
          dataIndex: 'startDate',
          title: t('aipskd.optionDetail.taskStartDate'),
          ellipsis: true,
          width: 100,
        },
        {
          key: 'SuccessorEndDate',
          dataIndex: 'endDate',
          title: t('aipskd.optionDetail.taskEndDate'),
          ellipsis: true,
          width: 100,
        },
        {
          key: 'SuccessorDependencyType',
          dataIndex: 'dependencyType',
          title: 'Ralation',
          ellipsis: true,
          width: 70,
        },
        {
          key: 'SuccessorLag',
          dataIndex: 'lag',
          title: 'Lag',
          ellipsis: true,
          width: 60,
        },
        {
          key: 'SuccessorFreeFloat',
          dataIndex: 'freeFloat',
          title: 'Free Float',
          ellipsis: true,
        },
        {
          key: 'SuccessorDriving',
          dataIndex: 'driving',
          title: 'Driving',
          ellipsis: true,
          filterMultiple: false,
          filters: [
            {
              text: 1,
              value: 1,
            },
            {
              text: 0,
              value: 0,
            },
          ],
          onFilter: (value, record) => record.driving === value,
        },
      ],
    },
  ]);

  const [activeTask, setActiveTask] = useState();
  const [predecessorData, setPredecessorData] = useState([]);
  const [successorData, setSuccessorData] = useState([]);

  const taskClickHandler = ({ source, taskRecord, event }) => {
    if (!projectReady.current) {
      return;
    }
    if (taskRecord.children) {
      return;
    }

    modeDispatch({ type: 'showTaskCard' });
    const typeList: any = {
      0: 'SS',
      1: 'SF',
      2: 'FS',
      3: 'FF',
    };
    const { taskId, dependencies } = taskRecord;
    setActiveTask(taskId);
    const dataInfo = dependencies.reduce(
      (acc, cur) => {
        if (cur.to === taskId) {
          const task = cur.fromTask;
          acc.predecessor.push({
            taskId: task.taskId,
            taskName: task.name,
            startDate: getDayString(task.startDate),
            endDate: getDayString(task.endDate),
            dependencyType: typeList[cur.type],
            lag: cur.lag,
            freeFloat: cur.freeFloat,
            driving: cur.isDrivingPath ? 1 : 0,
          });
        }
        if (cur.from === taskId) {
          const task = cur.toTask;
          acc.successor.push({
            taskId: task.taskId,
            taskName: task.name,
            startDate: getDayString(task.startDate),
            endDate: getDayString(task.endDate),
            dependencyType: typeList[cur.type],
            lag: cur.lag,
            freeFloat: cur.freeFloat,
            driving: cur.isDrivingPath ? 1 : 0,
          });
        }
        return acc;
      },
      {
        predecessor: [],
        successor: [],
      }
    );

    setPredecessorData(dataInfo.predecessor);
    setSuccessorData(dataInfo.successor);
  };

  const containerStyle = {
    display: 'flex',
    border: '1px solid #f0f0f0',
    borderRadius: '0 0 4px 4px',
    padding: 6,
    overflow: 'hidden',
    width: '100%',
    flex: 1,
  };

  const titleClickHandler = () => {
    ganttSub.current.scrollToTask(selected);
  };

  const [relatedTasks, setRelatedTasks] = useState([]);

  useEffect(() => {
    if (calenderOrg) {
      const calenderData = calenderOrg.filter((calender) => calender.workingDay);
      setCalenderData(calenderData);
    }
  }, [calenderOrg]);

  return (
    <>
      <div className="bryntum-gantt-container">
        <BryntumGantt key={ganttKey} ref={gantt} height={height} {...ganttCardConfig} />
        {mode.viewMode === 'table-card' && (
          <Card
            styles={{
              header: {
                minHeight: 42,
              },
              body: {
                height: 255,
              },
            }}
            title={
              <span
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  toTaskPosition(activeTask);
                }}
              >
                <Button
                  style={{ color: '#1a4f99', fontWeight: '500', padding: 0 }}
                  type="link" size="large">
                  {activeTask}
                </Button>
              </span>
            }
            extra={
              <CloseOutlined
                onClick={() => {
                  modeDispatch({ type: 'closeTaskCard' });
                }}
              />
            }
          >
            <Row>
              <Col span={12}>
                <Table
                  bordered
                  scroll={{
                    y: 152,
                  }}
                  size="small"
                  pagination={false}
                  columns={predecessorColumns}
                  dataSource={predecessorData}
                />
              </Col>
              <Col span={12}>
                <Table
                  bordered
                  scroll={{
                    y: 152,
                  }}
                  size="small"
                  pagination={false}
                  columns={successorColumns}
                  dataSource={successorData}
                />
              </Col>
            </Row>
          </Card>
        )}
        <Modal
          destroyOnClose
          title={
            <Flex gap={16}>
              <span
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  titleClickHandler();
                }}
              >
                {selected?.taskId} Related Path
              </span>
              <Switch
                checkedChildren={<>Driving Only</>}
                unCheckedChildren={<>Related Path</>}
                value={drivingOnly}
                onChange={(checked) => {
                  setDrivingOnly(checked);
                }}
              />
            </Flex>
          }
          width={document.body.clientWidth * 0.9}
          styles={{
            body: {
              height: document.body.clientHeight * 0.75,
            },
          }}
          open={relatedPathOpen}
          onCancel={() => {
            setRelatedPathOpen(false);
          }}
          footer={null}
          getContainer={() => document.querySelector('.aipskd')}
          afterOpenChange={(open) => {
            console.log('afterOpenChange');
            setSubGanttModalReady(open);
          }}
        >
          <RelatedPathContext.Provider
            value={{
              drivingOnly,
              selected,
              relatedTasks,
              setRelatedTasks,
            }}
          >
            {selected && relatedPathOpen && subGanttModalReady && (
              <Flex style={{ height: '100%' }} vertical>
                <div style={containerStyle}>
                  <GanttSubChart ref={ganttSub} isReady={subGanttModalReady} />
                </div>
                <div style={containerStyle}>
                  <PathMpPeak optimizeId={optimize?.id} calenderData={calenderData} />
                </div>
              </Flex>
            )}
          </RelatedPathContext.Provider>
        </Modal>
      </div>
    </>
  );
};
export default GanttChart;
